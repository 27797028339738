import { takeLatest, put, select } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { API_V2 } from "utils/API/API";
import { REPOSITORIES, REPOSITORIES_FOLDER, REPOSITORIES_SEARCH } from "utils/constants/endpoints";
import { Types } from "../ducks/files";

const getFoldersActual = (state) => state.files.folders;
const pageActual = (state) => state.files.page;
const filterActual = (state) => state.files.filter;
const displayActual = (state) => state.files.display;
const fileTypeActual = (state) => state.files.fileType;

function* getAllFiles({ payload }) {
  const pageRedux = yield select(pageActual)
  const page = payload?.page || pageRedux;
  //Page 1 enviado no payload para começar uma nova paginação
  //PageRedux para continuar uma paginação

  const filter = yield select(filterActual);
  const display = yield select(displayActual);
  const fileType = yield select(fileTypeActual);

  const search = payload?.search || "";

  try {
    //Para quando se está numa busca com search e filtro por arquivo e decide apagar a busca
    //O filtro por arquivo deixaria de funcionar, logo mudamos para name

    const filterConverted = !search && filter === "file_type" ? "name" : filter;

    if (!search && filter === "file_type") {
      yield put({
        payload: filterConverted,
        type: Types.SET_FILTER
      });
    }

    const searchEscaped = encodeURIComponent(search);

    const { data: { pagination, results, repositories: list } } = yield API_V2({
      method: "GET",
      url:
        !!search ?
          `${REPOSITORIES_SEARCH}?filter_type=${display}&per_page=25&page=${page}&file_name=${searchEscaped}&sort_by=${filterConverted}&file_type=${fileType}&origin=web`
          : `${REPOSITORIES}?per_page=25&page=${page}&sort_by=${filterConverted}&origin=web`,
    });

    const repositories = results ?? list;

    const actualList = page === 1 ? [] : yield select(getFoldersActual);
    const newList = actualList.concat(repositories);
    const hasMore = repositories.length === 25;
    const nextpage = page + 1;

    yield put({
      folders: newList,
      hasMore,
      page: nextpage,
      search,
      pagination,
      type: Types.GET_FILES_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_FILES_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

function* getFilesFolder({ id }) {
  try {
    const repository = yield requestAPI({
      verb: GET,
      endPoint: REPOSITORIES_FOLDER,
      id,
    });
    yield put({
      repository,
      type: Types.GET_FILES_FOLDER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_FILES_FOLDER_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

function* searchFiles({ payload }) {
  try {
    const { files, repositories } = yield requestAPI({
      verb: GET,
      endPoint: payload
        ? `${REPOSITORIES}?filter=folder_or_file&folder_or_file_name=${payload}`
        : REPOSITORIES,
      data: null,
    });
    yield put({
      type: Types.SEARCH_FILES_SUCCESS,
      files,
      folders: repositories,
    });
  } catch (error) {
    yield put({
      type: Types.SEARCH_FILES_FAILURE,
      ...error.response.data,
      isLoading: false,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_FILES, getAllFiles);
  yield takeLatest(Types.GET_FILES_FOLDER, getFilesFolder);
  yield takeLatest(Types.SEARCH_FILES, searchFiles);
}
