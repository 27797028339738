/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useEffect, useCallback, useState } from "react";
import { style } from "style";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { Creators as AcademyActions } from "store/ducks/academy";

import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";
import { ReactComponent as GoBackIcon } from "assets/img/icons/goBackIcon.svg";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import Button from "components/core/Button";
import { manageClasses } from "helpers/sharedHelpers";
import urls from "utils/constants/urls";
import { Creators as ModalAlertActions } from "store/ducks/modalAlert";

import { useAnalytics } from "utils/hooks";
import { GoBackButton } from "containers/Mural/Publication/PublicationStyle";
import CourseSkeleton from "./CourseSkeleton";
import MyCourses from "../Sidebar/MyCourses";

import * as S from "./CourseStyle";

useAnalytics("screen_academy_course");

const Course = () => {
  const { id } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const goBack = () => history.goBack();
  const location = useLocation();
  const { pageTitle } = location.state || "";
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const changeDataPosition = () => {
    setDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", changeDataPosition);
    return () => window.removeEventListener("resize", changeDataPosition);
  });
  const { course_details: courseDetails, isLoading } = useSelector(
    ({ academy }) => academy,
  );

  const {
    title,
    image,
    description,
    has_certificate,
    user_status: userStatus,
    module_courses: courseModules,
    minimum_percentage_for_certificate,
    modality,
    validated,
  } = courseDetails;

  const dispatchDetailsCourse = useCallback(
    () => dispatch(AcademyActions.getDetailsCourse(id)),
    [dispatch, id],
  );

  useEffect(() => {
    dispatchDetailsCourse();
  }, [dispatchDetailsCourse]);

  const setUrlCurseName = (titleUrl) =>
    titleUrl
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/ /g, "-")
      .replace("#", "")
      .replace("/", "_");

  const toggleCollapse = (target) => {
    const svg = target.querySelector("svg");
    target.classList.toggle("uncollapsed");

    if (target.classList.contains("uncollapsed")) {
      target.style.height = "auto";
      svg.style.transform = "rotate(90deg)";
    } else {
      target.style.height = "30px";
      svg.style.transform = "rotate(-90deg)";
    }
  };

  const renderModule = (
    { id: moduleId, name, was_finished: completed, submodules },
    index,
  ) => {
    const inProgress =
      !completed && !!submodules.find(({ was_seen }) => was_seen);

    return (
      <S.CourseModule
        key={moduleId}
        variant={manageClasses({ completed, inProgress })}
        onClick={({ currentTarget }) => toggleCollapse(currentTarget)}
      >
        <S.CourseModuleDetails
          variant={manageClasses({ completed, inProgress })}
        >
          <span>{index + 1}</span>
          <S.CourseModuleTitle>{name}</S.CourseModuleTitle>
          <ArrowLeftIcon />
        </S.CourseModuleDetails>
        <S.CourseTasksList>
          {submodules.map(
            (
              { id: submoduleID, name: taskTitle, was_seen: completedSub },
              taskIndex,
            ) => {
              const inSubProgress =
                taskIndex !== 0 &&
                submodules[taskIndex - 1].was_seen &&
                !completedSub;
              return (
                <S.CourseTask
                  key={submoduleID}
                  variant={manageClasses({ completedSub, inSubProgress })}
                >
                  <S.CourseTaskLink
                    onClick={() =>
                      completedSub &&
                      history.push(
                        `${urls.ROUTES.ACADEMY}/${id}/${setUrlCurseName(
                          title,
                        )}`,
                        {
                          module: index,
                          submodule: taskIndex,
                          pageTitle,
                        },
                      )
                    }
                    completed={completedSub}
                  >
                    <span>
                      {index + 1}.{taskIndex + 1}
                    </span>
                    {` ${taskTitle}`}
                  </S.CourseTaskLink>
                </S.CourseTask>
              );
            },
          )}
        </S.CourseTasksList>
      </S.CourseModule>
    );
  };

  const restartCourse = () => {
    dispatch(
      ModalAlertActions.setAlertsRequest({
        title: intl.messages["academy.couseDetail.finalizedCourse"],
        msg: `Para um melhor aproveitamento você deve ter mais de ${minimum_percentage_for_certificate}% das respostas corretas, está preparado para tentar novamente?`,

        buttons: [
          {
            title: intl.messages["general.buttons.back"],
            variant: ["transparent"],
          },
          {
            title: intl.messages["general.buttons.confirm"],
            event: () => dispatch(AcademyActions.resetCourse({ id })),
          },
        ],
      }),
    );
  };
  return (
    <Portal fullHeight sidebarContext={<MyCourses />}>
      {isLoading ? (
        <CourseSkeleton />
      ) : (
        <Flex>
          {isDesktop && (
            <GoBackButton onClick={goBack} isCursesPage>
              <GoBackIcon />
            </GoBackButton>
          )}
          <S.CourseDetail>
            {!isDesktop && (
              <GoBackButton onClick={goBack} isCursesPage>
                <GoBackIcon />
              </GoBackButton>
            )}
            <S.CourseHeader>
              <S.CourseCover src={image?.url} alt={title} />
              <S.CourseInfo>
                <S.CourseTitle
                  completed={userStatus?.status === "finished"}
                  started={userStatus?.status === "initiated"}
                >
                  {title}
                </S.CourseTitle>
                <S.CourseDescription>{description}</S.CourseDescription>
              </S.CourseInfo>
            </S.CourseHeader>

            <h2>{intl.messages["academy.courseDetail.modules"]}</h2>
            <S.CourseModulesList>
              {courseModules?.map((courseModule, moduleIndex) =>
                renderModule(courseModule, moduleIndex),
              )}
            </S.CourseModulesList>
            <Flex
              p={2}
              justifyContent="flex-end"
              spaceChildren={1}
              marginTop="15px"
            >
              <Button
                name="Voltar"
                variant={manageClasses(
                  userStatus?.status === "initiated"
                    ? "inProgressTransparent"
                    : userStatus?.status === "finished"
                    ? "completedTransparent"
                    : "transparent",
                )}
                action={() =>
                  history.push(`${urls.ROUTES.ACADEMY}`, { pageTitle })
                }
              />

              {(!style.variables.child_profiles?.length > 0 ||
                userStatus?.status !== "finished") &&
                (userStatus?.status === "finished" ||
                (modality === "presential" && validated) ? (
                  has_certificate ? (
                    <Button
                      name={
                        userStatus?.is_entitled_to_certificate
                          ? "Certificado"
                          : "Refazer Curso"
                      }
                      variant={["completed"]}
                      action={
                        userStatus?.is_entitled_to_certificate ||
                        (modality === "presential" && validated)
                          ? () => {
                              history.push(
                                `${urls.ROUTES.ACADEMY}/${id}/${setUrlCurseName(
                                  title,
                                )}/certificado`,
                                { pageTitle },
                              );
                            }
                          : restartCourse
                      }
                    />
                  ) : (
                    !userStatus.minimum_hits && (
                      <Button
                        name={
                          userStatus?.is_entitled_to_certificate
                            ? "Refazer Curso"
                            : "Reiniciar"
                        }
                        variant={["completed"]}
                        action={restartCourse}
                      />
                    )
                  )
                ) : (
                  modality === "online" && (
                    <Button
                      name={
                        userStatus?.status === "initiated"
                          ? `Continuar`
                          : `Iniciar`
                      }
                      variant={manageClasses(
                        userStatus?.status === "initiated" && "inProgress",
                      )}
                      action={() =>
                        history.push(
                          `${urls.ROUTES.ACADEMY}/${id}/${setUrlCurseName(
                            title,
                          )}`,
                          {
                            module: location.state?.module,
                            submodule: location.state?.module,
                            pageTitle,
                          },
                        )
                      }
                    />
                  )
                ))}
            </Flex>
          </S.CourseDetail>
        </Flex>
      )}
    </Portal>
  );
};

export default Course;
