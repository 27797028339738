import React, { useRef, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { ModalProvider } from "styled-react-modal";
import urls from "utils/constants/urls";
import { useAnalytics } from "utils/hooks";
import { Creators as AcademyActions } from "store/ducks/academy";

import Button from "components/core/Button";
import Portal from "templates/Portal";
import { Input } from "components/core/Form/Form";
import Flex from "components/core/Grid/Flex";
import { history } from "helpers/sharedHelpers";
import { PageTitle } from "components/core/Typography/Typography";
import MyCourses from "./Sidebar/MyCourses";
import AcademySkeleton from "./AcademySkeleton";
import AcademyMoreSkeleton from "./AcademyMoreSkeleton";

import { ReactComponent as NoResults } from "assets/img/hasNoResults.svg";

import * as S from "./AcademyStyle";

useAnalytics("screen_academy");

const Academy = () => {
  const { voucherSuccess, errors } = useSelector((state) => state.academy);
  const intl = useIntl();
  const location = useLocation();
  const { ordered_menus } = useSelector((state) => state.customMenu);
  const pageTitle =
    location.state?.pageTitle ||
    ordered_menus?.find((item) => item.kind === "academy")?.title;
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const [scrollParentRef, setScrollParentRef] = useState();
  const [idCourse, setIdCourse] = useState("");
  const [title, setTitle] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(voucherSuccess);
  const { coursesList, hasMore, page, isLoading } = useSelector(
    ({ academy }) => academy,
  );

  useEffect(() => {
    dispatch(AcademyActions.getCourses());
  }, [dispatch]);

  useEffect(() => {
    setIsSuccess(voucherSuccess);
  }, [voucherSuccess]);

  const fetchMoreData = () => {
    // eslint-disable-next-line no-unused-expressions
    hasMore && dispatch(AcademyActions.getCourses());
  };

  const toggleModal = () => {
    setIsSuccess(false);
    setIsOpen(!isOpen);
  };

  const handleVoucher = (e, id, txt) => {
    e.stopPropagation();

    setTitle(txt);
    setIdCourse(id);
    setLoader(false);
    toggleModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const id = formData.get("id");
    const code = formData.get("code");

    setLoader(true);
    dispatch(AcademyActions.postVoucher({ idCourse: id, code }));
  };

  return (
    <Portal
      fullHeight
      sidebarContext={<MyCourses />}
      setRef={setScrollParentRef}
      textBtnInSideBar={intl.messages["academy.sidebar.title"]}
    >
      <Flex flexDirection="column" paddingBottom>
        <S.ContentPagetitle>
          <PageTitle
            noBorder
            title={pageTitle || intl.messages["menu.academy"]}
          />
        </S.ContentPagetitle>
        {page === 1 && isLoading ? (
          <AcademySkeleton />
        ) : (
          <Flex flexDirection="column" p={3}>
            <S.Subtitles>
              <h3>Legenda:</h3>
              <S.Status bg="secondaryColor">Disponíveis</S.Status>
              <S.Status bg="blue">Em progresso</S.Status>
              <S.Status bg="greenDark">Concluídos</S.Status>
            </S.Subtitles>
            <>
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={fetchMoreData}
                hasMore={hasMore}
                useWindow={false}
                threshold={10}
                getScrollParent={() => scrollParentRef}
                loader={<AcademyMoreSkeleton />}
              >
                <S.CoursesList ref={listRef}>
                  {coursesList?.map(
                    ({
                      id,
                      title: the_title,
                      image,
                      modules_amount: moduleLength,
                      description,
                      available,
                      user_status,
                      modality,
                    }) =>
                      available && (
                        <S.CourseCard
                          onClick={() =>
                            history.push(`${urls.ROUTES.ACADEMY}/${id}`, {
                              pageTitle,
                            })
                          }
                        >
                          <img src={image.url} alt={the_title} loading="lazy" />
                          <S.CourseInfo>
                            <h1 title={the_title.length > 44 ? the_title : ""}>
                              {the_title.length > 44
                                ? the_title.slice(0, 44) + "..."
                                : the_title}
                            </h1>
                            <S.ModulesCount status={user_status?.status}>
                              {moduleLength}{" "}
                              {moduleLength > 1 ? "Módulos" : "Módulo"}
                            </S.ModulesCount>
                            {modality === "online" ? (
                              <p>{description}</p>
                            ) : the_title.length > 44 ? (
                              <p title={description}>
                                {description.slice(0, 22) + "..."}
                              </p>
                            ) : (
                              <p>{description}</p>
                            )}
                          </S.CourseInfo>
                          {modality === "presential" && (
                            <Button
                              name={intl.messages["modal.textPage.validCourse"]}
                              variant="initiated"
                              onClick={(e) => handleVoucher(e, id, the_title)}
                              // action={() =>
                              //   history.push(
                              //     `${urls.ROUTES.ACADEMY}/${id}/${setUrlCurseName(
                              //       title,
                              //     )}`,
                              //   )
                              // }
                            />
                          )}
                        </S.CourseCard>
                      ),
                  )}
                </S.CoursesList>
              </InfiniteScroll>
              {!coursesList?.length > 0 && (
                <S.ContainerNoResults>
                  <NoResults />
                  <S.ContainerTittle>
                    {
                      intl.messages[
                        "academy.textPage.noCoursesAvailableToStartAtThisTime"
                      ]
                    }
                  </S.ContainerTittle>
                </S.ContainerNoResults>
              )}
            </>
          </Flex>
        )}
      </Flex>
      <ModalProvider>
        <S.StyledModal
          data-testid="modal-component"
          isOpen={isOpen}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
        >
          <S.ContainerModal>
            {isSuccess ? (
              <>
                <svg
                  width="154"
                  height="154"
                  viewBox="0 0 154 154"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M76.992 0C34.548 0 0 34.551 0 76.992C0 119.436 34.551 153.984 76.992 153.984C119.436 153.984 153.984 119.433 153.984 76.992C153.984 34.548 119.433 0 76.992 0ZM76.992 13.9794C111.87 13.9794 140.013 42.1161 140.013 76.9944C140.013 111.869 111.869 139.979 76.992 139.979C42.117 139.979 14.007 111.87 14.007 76.9944C14.007 42.1164 42.1167 13.9794 76.992 13.9794ZM111.876 48.9054C110.05 48.9534 108.316 49.714 107.042 51.0241L69.9921 88.0741L53.9481 72.0301C52.645 70.6731 50.8509 69.8973 48.9687 69.8786C47.0879 69.8586 45.2784 70.5981 43.9485 71.9282C42.6173 73.2582 41.879 75.0689 41.8977 76.9496C41.9165 78.8304 42.6923 80.6258 44.0493 81.9278L65.0562 102.928C66.3687 104.24 68.1498 104.977 70.0062 104.977C71.8613 104.977 73.6425 104.24 74.955 102.928L116.934 60.9488C118.302 59.6339 119.077 57.821 119.083 55.925C119.09 54.0277 118.326 52.2101 116.968 50.8871C115.609 49.5628 113.771 48.848 111.876 48.9043L111.876 48.9054Z"
                    fill="#FB8E2D"
                  />
                </svg>
                <S.SuccessText>
                  <S.TitleModal>
                    {intl.messages["modal.textPage.courseValidSuccess"]}
                  </S.TitleModal>
                </S.SuccessText>
              </>
            ) : (
              <>
                <S.TitleModal>
                  {intl.messages["modal.textPage.validCourse"]}
                </S.TitleModal>

                <S.SubTitleModal>{`${title} - ${intl.messages["modal.textPage.presentialCourse"]}`}</S.SubTitleModal>
                <S.Form
                  onSubmit={handleSubmit}
                  className={errors ? "error" : ""}
                >
                  <S.FormInput>
                    <input type="hidden" value={idCourse} name="id" />
                    <Input
                      name="code"
                      placeholder={intl.messages["modal.textPage.insertCode"]}
                      label={intl.messages["modal.textPage.insertCode"]}
                    />
                    {errors && <S.ErrorText>{errors[0]}</S.ErrorText>}
                  </S.FormInput>
                  <Flex spaceChildren={4}>
                    <Button
                      variant="transparent"
                      name={intl.messages["general.buttons.cancel"]}
                      onClick={toggleModal}
                    />
                    <Button
                      type="submit"
                      name={intl.messages["general.buttons.valid"]}
                      isLoading={loader}
                      variant={loader ? ["disabled"] : []}
                    />
                  </Flex>
                </S.Form>
              </>
            )}
          </S.ContainerModal>
        </S.StyledModal>
      </ModalProvider>
    </Portal>
  );
};

export default Academy;
