import styled from "styled-components";
import theme from "styles/theme";

export const FistLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  >svg#icon-close{
    margin: auto 0;
    width: 12px;
    height: 12px;
    path {
        stroke: #808080;
        stroke-width: 2;
    }
    position: relative;
    right: -9rem;
    cursor: pointer;
  }
`
export const TitleModal = styled.span`
  font-size: 2rem;
  font-weight: 400;

  color: #4B4E5C;
  font-family: "RubikRegular";
  text-align: center;
`

export const ScrollableDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 22rem;
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 22rem;
  margin: 2rem 0 1.5rem 0;

  > span {
    color: #4B4E5C;
    font-family: "RubikRegular";
    text-align: start;
    padding-left: 1.25rem;
    font-weight: 500;
    font-size: 1rem;
  }

  >div{
    height: auto;
    margin-left: 0;

    >div{
      background: transparent;
      >div{
        margin-bottom: 10px;
      }
    }
  }
`

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
`

export const TagDisplay = styled.div`
  border-radius: 1000px;
  padding: 8px 16px;
  font-size: 18px;
  font-family: "RubikRegular";
  border: 3px solid ${theme.colors.primaryColor};
  background-color: ${(props) => props.isActive ? theme.colors.primaryColor : "#fff"} !important;
  color: ${(props) => props.isActive ? "#fff" : theme.colors.primaryColor} !important;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.primaryColor} !important;
    color: #fff !important;
  }
`

export const FileTypeContainer = styled.div`
  width: 100%;
  padding-left: 1rem;

  >div{
    height: auto;
    margin-left: 0;
    margin-top: 0 !important;

    >div{
      background: transparent;
      margin-bottom: 5px !important;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`
