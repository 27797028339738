import { envs } from "helpers/envHelpers";
import { style } from "style";

const urls = {
  ROUTES_MENU: [
    {
      id: 0,
      patch: "/",
      nameIcon: "MuralIcons",
      showMenu: JSON.parse(envs.menu.menuMural),
      name: "mural",
    },
    {
      id: 1,
      patch: "/jeito-cibra-de-ser",
      nameIcon: "CibraIcon",
      showMenu: style.names.nameApp === "Time Cibra",
      name: "wayCibraOfBeing",
    },
    {
      id: 2,
      patch: "/responsabilidade-social",
      nameIcon: "ResponsibilityIcon",
      showMenu: style.names.nameApp === "Time Cibra",
      name: "socialResponsability",
    },
    {
      id: 3,
      patch: "/favoritos",
      nameIcon: "FavoriteIcons",
      showMenu: JSON.parse(envs.menu.menuFavorite),
      name: "favorite",
    },
    {
      id: 4,
      patch: "/grupo-pardini",
      nameIcon: "GroupPardini",
      showMenu: JSON.parse(envs.menu.menuGroupPardini),
      name: "groupPardini",
    },
    {
      id: 5,
      patch: "/sobre-nos",
      nameIcon: "AboutUs",
      showMenu: JSON.parse(envs.menu.menuAboutUs),
      name: "aboutUs",
    },
    {
      id: 6,
      patch: "/ferramentas",
      nameIcon: "AppsIcons",
      showMenu: JSON.parse(envs.menu.menuApps),
      name: "apps",
    },
    {
      id: 7,
      patch: "/calendario",
      nameIcon: "EventsIcons",
      showMenu: JSON.parse(envs.menu.menuCalendar),
      name: "calendar",
    },
    {
      id: 8,
      patch: "/historico-de-pontuacao",
      nameIcon: "NozIcon",
      showMenu: localStorage.getItem("roles") !== "guest",
      name: "scoreHistory",
    },
    {
      id: 9,
      patch: "/aniversarios",
      nameIcon: "BirthdaysIcons",
      showMenu:
        JSON.parse(envs.menu.menuBirthdays) && style.names.nameApp !== "Ecom",
      name: "birthdays",
    },
    {
      id: 10,
      patch: "/academy",
      nameIcon: "AcademyIcons",
      showMenu: JSON.parse(envs.menu.menuAcademy),
      name: "academy",
    },
    {
      id: 11,
      patch: "/loja",
      nameIcon: "StoreIcons",
      showMenu: localStorage.getItem("roles") !== "guest",
      name: "store",
    },
    {
      id: 12,
      patch: "/time",
      nameIcon: "SquadIcons",
      showMenu: JSON.parse(envs.menu.menuTeams),
      name: "squad",
    },
    {
      id: 13,
      patch: "/arquivos/pastas",
      nameIcon: "ArquivesIcons",
      showMenu: JSON.parse(envs.menu.menuFiles),
      name: "arquives",
    },
    {
      id: 14,
      patch: "/holerite-cibra",
      nameIcon: "HoleriteIcon",
      showMenu: style.names.nameApp === "Time Cibra",
      name: "holerite",
    },
    {
      id: 15,
      patch: "/servicos-machado",
      nameIcon: "",
      showMenu: style.names.nameApp === "somosmachado",
      name: "services",
    },
    {
      id: 16,
      patch: "/sac",
      nameIcon: "SacIcon",
      showMenu:
        style.names.nameApp === "Pintou Parceria Suvinil" ||
        style.names.nameApp === "sample",
      name: "sac",
    },
    {
      id: 17,
      patch: "/configuracoes",
      nameIcon: "ConfigurationsIcons",
      showMenu: JSON.parse(envs.menu.menuConfigurations),
      name: "settings",
    },
  ],
  ROUTES: {
    LOGIN: "/login",
    MURAL: "/",
    FAVORITE: "/favoritos",
    MY_NOTIFICATIONS: "/minhas-notificacoes",
    DETAILS_PRODUCT: "/produto",
    LOGOUT: "/logout",
    FILES: "/arquivos",
    STORE: "/loja",
    TEAM: "/time",
    BIRTHDAYS: "/aniversarios",
    BIRTHDAYS_TODAY: "/aniversariantes",
    CALENDAR: "/calendario",
    PROFILE: "/perfil",
    EDIT_PROFILE: "/editar-perfil",
    ACADEMY: "/academy",
    SCORE_HISTORY: "/historico-de-pontuacao",
    FORGET_PASSWORD: "/esqueci-a-senha",
    DETAILS_PUBLICATION: "/detalhes-publicacao",
    CONFIGURATIONS: "/configuracoes",
    APPS: "/ferramentas",
    SIGNUP: "/cadastro",
    DETAILS_GALLERY: "/galeria",
    GROUP_PARDINI: "/grupo-pardini",
    SUB_MENU_PARDINI: "/grupo-pardini",
    OUR_COMPANIES_PARDINI: "/sub-menu",
    OUR_COMPANIES_PUBLICATION: "/detalhes",
    ABOUT_US: "/sobre-nos",
    SUB_ABOUT_US: "/sobre-nos",
    HOLERITE: "/holerite",
    CIBRA_TEAMS: "/teams-auth",
    WAY_CIBRA_OF_BEING: "/jeito-cibra-de-ser",
    SOCIAL_RESPONSABILITY: "/responsabilidade-social",
    PRIVACY_POLICY: "/politica-privacidade",
    RESEARCH_SUVINIL: "/pesquisa-suvinil",
    SERVICES_MACHADO: "/servicos-machado",
    SAC_SUVINIL: "/sac",
  },
};

export default urls;

export const urlsFunction = (() => ({
  ROUTES_MENU: [
    {
      id: 0,
      patch: "/",
      nameIcon: "MuralIcons",
      showMenu: JSON.parse(envs.menu.menuMural),
      name: "feed",
    },
    {
      id: 1,
      patch: "/jeito-cibra-de-ser",
      nameIcon: "CibraIcon",
      showMenu: style.names.nameApp === "Time Cibra",
      name: "wayCibraOfBeing",
    },
    {
      id: 2,
      patch: "/responsabilidade-social",
      nameIcon: "SocialResponsibilityIcon",
      showMenu: style.names.nameApp === "Time Cibra",
      name: "socialResponsability",
    },
    {
      id: 3,
      patch: "/favoritos",
      nameIcon: "FavoriteIcons",
      showMenu: JSON.parse(envs.menu.menuFavorite),
      name: "favorite_posts",
    },
    {
      id: 4,
      patch: "/grupo-pardini",
      nameIcon: "GroupPardini",
      showMenu: JSON.parse(envs.menu.menuGroupPardini),
      name: "groupPardini",
    },
    {
      id: 5,
      patch: "/sobre-nos",
      nameIcon: "AboutUs",
      showMenu: JSON.parse(envs.menu.menuAboutUs),
      name: "aboutUs",
    },
    {
      id: 6,
      patch: "/ferramentas",
      nameIcon: "AppsIcons",
      showMenu: !(
        style.variables.child_profiles.length > 0 &&
        localStorage.getItem("roles") === "legal_person"
      ),
      name: "tools",
    },
    {
      id: 7,
      patch: "/calendario",
      nameIcon: "EventsIcons",
      showMenu: JSON.parse(envs.menu.menuCalendar),
      name: "calendar",
    },
    {
      id: 8,
      patch: "/historico-de-pontuacao",
      nameIcon: "NozIcon",
      showMenu: localStorage.getItem("roles") !== "guest",
      name: "score_history",
    },
    {
      id: 9,
      patch: "/aniversarios",
      nameIcon: "BirthdaysIcons",
      showMenu:
        JSON.parse(envs.menu.menuBirthdays) && style.names.nameApp !== "Ecom",
      name: "birthdays",
    },
    {
      id: 10,
      patch: "/academy",
      nameIcon: "AcademyIcons",
      showMenu: JSON.parse(envs.menu.menuAcademy),
      name: "academy",
    },
    {
      id: 11,
      patch: "/loja",
      nameIcon: "StoreIcons",
      showMenu: localStorage.getItem("roles") !== "guest",
      name: "market",
    },
    {
      id: 12,
      patch: "/time",
      nameIcon: "SquadIcons",
      showMenu: JSON.parse(envs.menu.menuTeams),
      name: "team",
    },
    {
      id: 13,
      patch: "/arquivos/pastas",
      nameIcon: "ArquivesIcons",
      showMenu: JSON.parse(envs.menu.menuFiles),
      name: "files",
    },
    {
      id: 14,
      patch: "/holerite",
      nameIcon: "HoleriteIcon",
      showMenu: style.names.nameApp === "Time Cibra",
      name: "holerite",
    },
    {
      id: 15,
      patch: "/servicos-machado",
      nameIcon: "",
      showMenu: style.names.nameApp === "somosmachado",
      name: "services",
    },
    {
      id: 16,
      patch: "/sac",
      nameIcon: "SacIcon",
      showMenu:
        style.names.nameApp === "Pintou Parceria Suvinil" ||
        style.names.nameApp === "sample",
      name: "sac",
    },
    {
      id: 17,
      patch: "/configuracoes",
      nameIcon: "ConfigurationsIcons",
      showMenu: JSON.parse(envs.menu.menuConfigurations),
      name: "settings",
    },
  ],
  ROUTES: {
    LOGIN: "/login",
    MURAL: "/",
    FAVORITE: "/favoritos",
    MY_NOTIFICATIONS: "/minhas-notificacoes",
    DETAILS_PRODUCT: "/produto",
    LOGOUT: "/logout",
    FILES: "/arquivos",
    STORE: "/loja",
    TEAM: "/time",
    BIRTHDAYS: "/aniversarios",
    BIRTHDAYS_TODAY: "/aniversariantes",
    CALENDAR: "/calendario",
    PROFILE: "/perfil",
    EDIT_PROFILE: "/editar-perfil",
    ACADEMY: "/academy",
    SCORE_HISTORY: "/historico-de-pontuacao",
    FORGET_PASSWORD: "/esqueci-a-senha",
    DETAILS_PUBLICATION: "/detalhes-publicacao",
    CONFIGURATIONS: "/configuracoes",
    APPS: "/ferramentas",
    SIGNUP: "/cadastro",
    DETAILS_GALLERY: "/galeria",
    GROUP_PARDINI: "/grupo-pardini",
    SUB_MENU_PARDINI: "/grupo-pardini",
    OUR_COMPANIES_PARDINI: "/sub-menu",
    OUR_COMPANIES_PUBLICATION: "/detalhes",
    ABOUT_US: "/sobre-nos",
    SUB_ABOUT_US: "/sobre-nos",
    HOLERITE: "/holerite",
    WAY_CIBRA_OF_BEING: "/jeito-cibra-de-ser",
    SOCIAL_RESPONSABILITY: "/responsabilidade-social",
    PRIVACY_POLICY: "/politica-privacidade",
    RESEARCH_SUVINIL: "/pesquisa-suvinil",
    SERVICES_MACHADO: "/servicos-machado",
    SAC_SUVINIL: "/sac",
  },
}))();
