import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import ManagerFiles from "components/presentation/ManagerFiles";
import GalleryFiles from "components/presentation/GalleryFiles";
import { Creators as FilesActions } from "store/ducks/files";
import { Creators as GalleryActions } from "store/ducks/gallery";
import { useAnalytics } from "utils/hooks";
import { PageTitle } from "components/core/Typography/Typography";
import { fileMenuName } from "../../locale";
import { useIsScrollable } from "../../utils/hooks/useIsScrollable";

import { Creators as ModalAlertActions } from "store/ducks/modalAlert";

import { ReactComponent as RepeatIcon } from "assets/img/icons/repeatIcon.svg";
import { ReactComponent as IconSearch } from "assets/img/icons/magnifierIcon.svg";
import { ReactComponent as IconFilter } from "assets/img/icons/filters.svg";

import {
  ContainerFilter,
  ContainerSearch,
  ContainerSearchAndFilter,
  ContainerSelectedOption,
  InputSearch,
  ContainerResults,
  ContainerRight,
} from "./FilesStyle";

useAnalytics("screen_directories");

const Files = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const intl = useIntl();
  const location = useLocation();
  const { pageTitle } = location.state || "";
  const [selecteShow, setSelecteShow] = useState(type === "galeria");
  const [scrollParentRef, setScrollParentRef] = useState();

  const [searchString, setSearchString] = useState("");
  const [previousSearch, setPreviousSearch] = useState("");

  const {
    folders,
    repository,
    searchingFor,
    isLoading,
    hasMore,
    page,
    search,
    filter,
    display,
    pagination,
  } = useSelector((state) => state.files);

  useEffect(() => {
    if (search) setSearchString(search);
  }, []);

  const {
    galleryList,
    isLoadingGallery,
    hasMoreGallery,
    pageGallery,
  } = useSelector((state) => state.gallery);
  const dispatchGetFiles = useCallback(
    (payload) => dispatch(FilesActions.getFiles(payload)),
    [dispatch],
  );

  const dispatchGetGallery = useCallback(
    () => dispatch(GalleryActions.getGallery()),
    [dispatch],
  );

  useEffect(() => {
    if (selecteShow) {
      hasMoreGallery && dispatchGetGallery();
    } else {
      hasMore && dispatchGetFiles();
    }
  }, [selecteShow, dispatchGetFiles, dispatchGetGallery]);

  //Gallery fetch functions

  const fetchMoreGalleryData = () => {
    hasMoreGallery &&
      !isLoadingGallery &&
      dispatch(GalleryActions.getGallery());
  };

  const [isScrollableGallery, refGallery, nodeGallery] =
    useIsScrollable(galleryList);

  useEffect(() => {
    if (!nodeGallery || isLoadingGallery) return;

    if (!isScrollableGallery && hasMoreGallery) {
      fetchMoreGalleryData();
    }
  }, [isLoadingGallery, isScrollableGallery, hasMoreGallery, nodeGallery]);

  //Files fetch functions

  const fetchMoreData = () => {
    hasMore && !isLoading && dispatch(FilesActions.getFiles({ search }));
  };

  const [isScrollableFolders, refFolders, nodeFolders] =
    useIsScrollable(folders);

  useEffect(() => {
    if (!nodeFolders || isLoading) return;

    if (!isScrollableFolders && hasMore) {
      fetchMoreData();
    }
  }, [isLoading, isScrollableFolders, hasMore, nodeFolders]);

  // Functions to Search and Filters Modal

  const handleOpenModal = () => {
    dispatch(
      ModalAlertActions.setAlertsRequest({
        modalType: "FilesFilter",
      }),
    );
  };

  useEffect(() => {
    let delaySearchString;
    if (
      searchString.trim().length > 0 ||
      previousSearch !== "" //in which case it is erasing a search
    ) {
      delaySearchString = setTimeout(() => {
        dispatchGetFiles({ search: searchString.trim(), page: 1 });
      }, 500);
    }
    return () => clearTimeout(delaySearchString);
  }, [searchString, previousSearch, dispatchGetFiles]);

  return (
    <Portal setRef={setScrollParentRef}>
      <Flex flexDirection="column" overflow="hidden">
        {searchingFor ? (
          <ManagerFiles repository={repository} />
        ) : (
          <>
            <PageTitle
              title={
                selecteShow
                  ? intl.messages["menu.gallery"]
                  : pageTitle || intl.messages["menu.files"]
              }
            >
              <ContainerRight>
                {!selecteShow && (
                  <ContainerSearchAndFilter>
                    <ContainerSearch>
                      <InputSearch
                        value={searchString}
                        onChange={(e) => {
                          setPreviousSearch(searchString);
                          setSearchString(e.target.value);
                        }}
                        placeholder={intl.messages["general.buttons.search"]}
                      />
                      <IconSearch className="magnifier" />
                    </ContainerSearch>

                    <ContainerFilter onClick={handleOpenModal}>
                      <IconFilter />
                    </ContainerFilter>
                  </ContainerSearchAndFilter>
                )}
                <ContainerSelectedOption
                  onClick={() => setSelecteShow(!selecteShow)}
                >
                  <RepeatIcon />
                  {selecteShow ? fileMenuName() : "galeria"}
                </ContainerSelectedOption>
              </ContainerRight>
            </PageTitle>

            {folders?.length > 0 && (
              <ContainerResults>
                {search && (
                  <>
                    <span>
                      {intl.messages["arquives.textPage.found"]}: {" "}
                      {pagination?.total_entries} {" "}
                      {intl.messages[`arquives.textPage.${display}`]}
                    </span>
                    <span>
                      {intl.messages["arquives.textPage.displayedBy"]} {" "}
                      {intl.messages[`arquives.textPage.${filter}`]}
                    </span>
                  </>
                )}
              </ContainerResults>
            )}

            {selecteShow ? (
              <Flex
                id="scrollableDivGallery"
                ref={refGallery}
                style={{ overflowY: "auto" }}
              >
                <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={fetchMoreGalleryData}
                  hasMore={hasMoreGallery}
                  useWindow={false}
                  threshold={1}
                  scrollableTarget="scrollableDivGallery"
                  style={{ width: "100%" }}
                >
                  <GalleryFiles
                    galleryList={galleryList}
                    pageGallery={pageGallery}
                    isLoadingGallery={isLoadingGallery}
                  />
                </InfiniteScroll>
              </Flex>
            ) : (
              <Flex
                id="scrollableDiv"
                ref={refFolders}
                style={{ overflowY: "auto", justifyContent: "center" }}
              >
                <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={fetchMoreData}
                  hasMore={hasMore}
                  useWindow={false}
                  threshold={1}
                  scrollableTarget="scrollableDiv"
                  style={{ width: "100%" }}
                >
                  <ManagerFiles
                    folders={folders}
                    page={page}
                    isLoading={isLoading}
                  />
                </InfiniteScroll>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Portal>
  );
};

export default Files;
