import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import Button from "../Button/Button";
import { RadioButtonGeneric } from "components/core/Form/Form";

import { ReactComponent as IconClose } from "assets/img/icons/iconClose.svg";
import { Creators as ModalAlertActions } from "store/ducks/modalAlert";
import { Creators as FilesActions } from "store/ducks/files";

import * as AlertModalStyle from "./AlertModalStyle";
import * as S from "./FilesFilterStyle";

const convertedDisplayMapping = {
  "all": "Todos",
  "folders": "Pastas",
  "files": "Arquivos"
}

const convertedFilterMapping = {
  "name": "Ordem alfabética",
  "upload": "Upload mais recente",
  "file_type": "Tipo do arquivo"
}

const convertedFileTypeMapping = {
  "text": "Documento de texto",
  "spreadsheet": "Planilhas",
  "presentation": "Apresentações",
  "pdf": "PDF's",
  "image": "Imagens"
}

const convertedFileTypeMappingToRequest = {
  "Documento de texto": "text",
  "Planilhas": "spreadsheet",
  "Apresentações": "presentation",
  "PDF's": "pdf",
  "Imagens": "image",
}

const sortByOptions = ["Ordem alfabética", "Upload mais recente"];

const sortByOptionsCompleted = ["Ordem alfabética", "Upload mais recente", "Tipo do arquivo"];

export const FilesFilter = () => {

  const dispatch = useDispatch();
  const intl = useIntl();

  const { alerts: dataAlerts } = useSelector((state) => state.alert);
  const { filter, isLoading, search, display, fileType } = useSelector((state) => state.files);

  const [convertedFilter, setConvertedFilter] = useState(
    convertedFilterMapping[filter]
  );

  const [convertedDisplay, setConvertedDisplay] = useState(
    convertedDisplayMapping[display]
  );

  const [convertedFileType, setConvertedFileType] = useState(
    convertedFileTypeMapping[fileType]
  );

  const dispatchGetFiles = useCallback(
    (payload) => dispatch(FilesActions.getFiles(payload)),
    [dispatch],
  );

  const handleCloseModal = () => {
    dispatch(ModalAlertActions.setAlertsRequest({}));
  };

  const handleChangeFilter = (filter) => {
    const filterRequest = filter === "Ordem alfabética" ? "name" : filter === "Upload mais recente" ? "upload" : "file_type";
    dispatch(FilesActions.setFilter(filterRequest));
  };

  const handleChangeDisplay = (display) => {
    const displayRequest = display === "Todos" ? "all" : display === "Pastas" ? "folders" : "files";
    dispatch(FilesActions.setDisplay(displayRequest));
  };

  const handleChangeFileType = (fileType) => {
    dispatch(FilesActions.setFileType(convertedFileTypeMappingToRequest[fileType]));
  };

  const handleApplyFilters = () => {
    handleChangeFilter(convertedFilter);
    handleChangeDisplay(convertedDisplay);
    handleChangeFileType(convertedFileType);
    dispatchGetFiles({ page: 1, search });
    handleCloseModal();
  }

  const fileTypesMapping = {
    "Tipo do arquivo":
      <S.FileTypeContainer>
        <RadioButtonGeneric
          optionSelected={convertedFileType}
          options={["Documento de texto", "Planilhas", "Apresentações", "PDF's", "Imagens"]}
          onChange={(e) => setConvertedFileType(e)}
        />
      </S.FileTypeContainer>
  }

  return (
    <AlertModalStyle.StyledModal
      data-testid="modal-component"
      isOpen={dataAlerts?.modalType === 'FilesFilter'}
      type={false}
      style={{ width: "29rem" }}
    >
      <AlertModalStyle.ContainerModal>
        <S.FistLineContainer>
          <S.TitleModal>{intl.messages["arquives.modal.filters"]}</S.TitleModal>
          <IconClose id="icon-close" onClick={handleCloseModal} />
        </S.FistLineContainer>
        <S.ScrollableDiv>
          {search && (
            <S.MainContainer style={{ marginBottom: '0' }}>
              <span>{intl.messages["arquives.modal.display"]}:</span>
              <S.TagsContainer>
                <S.TagDisplay
                  isActive={convertedDisplay === 'Todos'}
                  onClick={() => {
                    setConvertedDisplay('Todos');
                    if (convertedFilter === 'Tipo do arquivo') setConvertedFilter('Ordem alfabética');
                  }}
                >
                  Todos
                </S.TagDisplay>
                <S.TagDisplay
                  isActive={convertedDisplay === 'Arquivos'}
                  onClick={() => setConvertedDisplay('Arquivos')}
                >
                  Arquivos
                </S.TagDisplay>
                <S.TagDisplay
                  isActive={convertedDisplay === 'Pastas'}
                  onClick={() => {
                    setConvertedDisplay('Pastas');
                    if (convertedFilter === 'Tipo do arquivo') setConvertedFilter('Ordem alfabética');
                  }}
                >
                  Pastas
                </S.TagDisplay>
              </S.TagsContainer>
            </S.MainContainer>
          )}
          <S.MainContainer>
            <span>{intl.messages["arquives.modal.sortBy"]}:</span>
            <RadioButtonGeneric
              optionSelected={convertedFilter}
              options={search ? sortByOptionsCompleted : sortByOptions}
              onChange={(e) => {
                setConvertedFilter(e);
                if (e === 'Tipo do arquivo') setConvertedDisplay('Arquivos');
              }}
            />
            {search && fileTypesMapping[convertedFilter]}
          </S.MainContainer>
        </S.ScrollableDiv>
        <S.ButtonsContainer>
          <Button
            name={intl.messages["arquives.modal.applyFilters"]}
            variant={["big", "noTextTransform"]}
            onClick={handleApplyFilters}
            isLoading={isLoading}
          />
        </S.ButtonsContainer>
      </AlertModalStyle.ContainerModal>
    </AlertModalStyle.StyledModal>
  )
}
