import { style } from "style";

const base =
  style.api.newApiProduction === "null" ||
  style.api.newApiProduction === "undefined"
    ? style.api.apiProduction
    : style.api.newApiProduction;
const service = "api";
const version = "v1";
const canvas = "canvas_publications";

export const basePath = `${base}${service}/${version}`;
export const basePathV2 = `${base}${service}/v2`;
export const basePathV3 = `${base}${service}/v3`;

/* AUTH */
export const AUTH_USER = `${basePath}/users/auth/sign_in`;
export const GET_AUTH_PAINTER = "/auth";
export const RESET_PASSWORD = "/users/reset_password";

/* USER */
export const POST_USER = "users";
export const GET_USER = (id) => `users/${id}`;
export const GET_USERS_SECTOR = (name) => `users/?sector=${name}`;
export const GET_TERMS = "terms/";
export const GET_POLICIES_PRIVACY = "privacy_policies";
export const PUT_PASSWORD = (id) => `users/${id}/password`;
export const GET_SCORE_HISTORY = "users/score_history";
export const GET_ALL_BIRTHDAYS = "users/birthdays";
export const GET_BIRTHDAYS_USERS = "users/users_birthday";
export const GET_PERSONAL_QUESTIONS = "personal_questions";
export const GET_LIST_TO_MARK = (payload) =>
  `users/list_to_mark?name=${payload}`;
export const DELETE_USER_ACCOUNT = "users/erase_data";
export const GET_USER_ANSWER = "mood_surveys/user_answer";
export const GET_MOOD_SURVEY_CURRENT = "mood_surveys/current";
export const POST_MOOD_SURVEY_ANSWER = (idMoodSurvey) =>
  `mood_surveys/${idMoodSurvey}/answers`;
export const VISUALIZATION_MOOD_SURVEY_CURRENT = (idMoodSurvey) =>
  `mood_surveys/${idMoodSurvey}/visualizations`;
export const GET_LIST_TO_MARK_V2 = (
  page = 1,
  per_page = 10,
  name = '',
) =>
  !!name
    ? `users/list_to_mark?page=${page}&per_page=${per_page}&name=${name}`
    : `users/list_to_mark?page=${page}&per_page=${per_page}`;

/* MURAL */
export const POSTS_PUBLICATIONS = `${base}/${service}/v3/${canvas}/`;
export const MURAL_FORM_ANSWER = `${canvas}/`;

/* FAVORITE */
export const POST_FAVORITE_PUBLICATION = (id) =>
  `${canvas}/${id}/mark_as_favorite`;
export const DELETE_FAVORITE_PUBLICATION = (id) =>
  `${canvas}/${id}/remove_from_favorites`;

/* LIKE */
export const LIKE_PUBLICATIONS = (id) => `${canvas}/${id}/canvas_likes`;
export const LIKE_USERS_PUBLICATIONS = (id, page = 1) =>
  `${canvas}/${id}/canvas_users?page=${page}`;

/* REACTIONS */
export const PUBLICATION_REACTIONS = (reactionType, page = 1, id) =>
  `${basePathV2}/${LIKE_PUBLICATIONS(
    id,
  )}/reactions?reaction=${reactionType}?page=${page}`;

/* DETAILS PUBLICATION */
export const DETAILS_PUBLICATION = (id) => `${canvas}/${id}`;
export const VIEW_PUBLICATION = (id) => `${canvas}/${id}/?visualization=true`;
export const VIEW_PUBLICATION_V2 = (id) => `${canvas}/${id}/user_viewed`;
export const COMMENTS_PUBLICATION = (id) => `${canvas}/${id}/canvas_comments`;
export const FORM_ANSWER = (id) => `${canvas}/${id}/send_form_answer`;
export const SURVEY_ANSWER = `${canvas}/`;

/* NOTIFICATIONS */
export const NOTIFICATIONS = "notifications/";
export const GET_NOT_VIEWED_NOTIFICATIONS = "notificationsize/";
export const GET_NOT_VIEWED_NOTIFICATIONS_SIZE = "notifications/size/";
export const NOTIFICATIONS_MARK_AS_READ = "notifications/mark_as_read";

/* CALENDAR_EVENTS */
export const CALENDAR_EVENTS = "calendars/";

/* FILES */
export const REPOSITORIES = "repositories";
export const REPOSITORIES_SEARCH = "repositories/search_files";
export const REPOSITORIES_FOLDER = (id) => `repositories/${id}`;

/* GALLERY */
export const GALLERY = "galleries";
export const GALLERY_FOLDER = (id) => `galleries/${id}/gallery_contents`;

/* STORE */
export const GET_ALL_PRODUCTS = "products/";
export const GET_PRODUCT = (id) => `products/${id}`;
export const BUY_PRODUCT = "products/buy";
export const CONFIRM_PURCHASE = "products/confirm_purchase";
export const RESCUE = "products/myproducts";
export const RESCUE_DESPATCHED = (page) =>
  `products/myproducts?page=${page}&category=despatched`;
export const RESCUE_NOT_DESPATCHED = (page) =>
  `products/myproducts?page=${page}&category=not_despatched`;

/* COURSER */
export const COURSERS = "coursers";
export const COURSE = `${base}/${service}/v2/courses`;

/* TEAM */
export const GET_ALL_TEAMS = "sectors";
export const GET_SECTOR_USERS = "sectors/users";
export const GET_BRAND_FILTER = "brand_sectors";

/* APPS */
export const LINKS_APPS = "external_links";

/* AUTHENTICATED_SAML */
export const LOGIN_SAML = style.api.samlUrl;
export const LOGOUT_SAML = style.api.samlUrl?.replaceAll("login", "logout");

/* GROUP_PARDINI */
export const GET_PARDINI = (id) => `custom_menu_items/${id}`;
export const GET_PARDINI_DETAILS_PUBLISH = (id) =>
  `custom_menu_items/${id}/custom_pages`;

/* EXTERNAL_LINKS_AND_TOOLS */
export const TOOLS_EXTERNAL_LINKS = "external_link_sections";

/* TOKEN_TUTORIAL */
export const GET_TOKEN_TUTORIAL = "apple_tokens/get_token";

/* HOLERITE */
export const GET_HOLERITE = (period, kind) =>
  `${basePath}/get_pay_stub/?period=${period}&kind=${kind}`;
export const GUEST_AUTH = `${basePath}/users/auth/microsoft/sign_in`;
export const GET_HOLERITE_PDF = (period, kind) =>
  `${basePath}/get_pay_stub.pdf/?period=${period}&kind=${kind}`;

export const GET_SERVICES_MACHADDO = "users_integration/holerite";

/* MENUS TEAM CIBRA */
export const GET_CIBRA = (id) => `custom_menu_items/${id}`;
export const GET_CIBRA_DETAILS_PUBLISH = (id) =>
  `custom_menu_items/${id}/custom_pages`;

/** POST COMMENTS */
export const baseURLV3 = `${base}${service}/v3`;
export const baseURLV2 = `${base}${service}/v2`;

export const GET_POST_COMMENTS = (
  postId,
  page = 1,
  per_page = 10,
  commentId = false,
) =>
  !!commentId
    ? `/${canvas}/${postId}/canvas_comments?page=${page}&per_page=${per_page}&comment_id=${commentId}`
    : `/${canvas}/${postId}/canvas_comments?page=${page}&per_page=${per_page}`;

export const GET_COMMENT = (commentId) => `/canvas_comments/${commentId}`;
