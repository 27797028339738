import React from "react";
import Skeleton from "react-loading-skeleton";
import Flex from "components/core/Grid/Flex";
import { marginRight, width } from "styled-system";
import styled from "styled-components";

const SkeletonFiles = () => {
  const itens = [];
  for (let i = 0; i < 16; i++) {
    itens.push(
      <Flex
        flexDirection="column"
        alignItems="center"
        spaceChildren={1}
        mb={4}
        padding={2}
      >
        <Skeleton duration={0.8} width={125} height={125} />
        <Skeleton duration={0.8} width={99} height={42} />
      </Flex>,
    );
  }
  return itens;
};

const InfoContainerSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  padding-top: 5px;

  > div:last-child{
    margin-top: 8px;
  }
`

export const NewSkeletonFiles = () => {
  const itens = [];
  for (let i = 0; i < 16; i++) {
    itens.push(
      <Flex
        alignItems="start"
        spaceChildren={1}
        mb={4}
        padding={2}
        style={{
          width: '100%',
        }}
      >
        <Skeleton duration={0.8} width={56} height={56} />
        <InfoContainerSkeleton>
          <Skeleton duration={0.8} width={99} height={20} />
          <Skeleton duration={0.8} width={80} height={20} />
        </InfoContainerSkeleton>
      </Flex>,
    );
  }
  return itens;
};

export const SkeletonFile = () => (
  <Flex
    alignItems="start"
    spaceChildren={1}
    mb={4}
    padding={2}
    style={{ margin: '1rem auto 0 -0.25rem' }}
  >
    <Skeleton duration={0.8} width={56} height={56} />
    <InfoContainerSkeleton>
      <Skeleton duration={0.8} width={99} height={20} />
      <Skeleton duration={0.8} width={80} height={20} />
    </InfoContainerSkeleton>
  </Flex>
);

export default SkeletonFiles;
