import styled from "styled-components";
import css from "@styled-system/css";
import { style } from "../../style";

export const ContainerSelectedOption = styled("div")(
  css({
    fontSize: "14px",
    transition: "1s",
    cursor: "pointer",
    lineHeight: "24px",
    fontFamily: "text",
    fontStyle: "normal",
    borderRadius: "4px",
    padding: "6px 20px",
    textAlign: "center",
    fontWeight: "normal",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    color: "defaultBackground",
    backgroundColor: "primaryColor",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
    svg: {
      marginRight: "10px",
      path: {
        stroke: "defaultBackground",
      },
    },
    ":hover": {
      opacity: 0.7,
    },
  }),
);

export const ContainerSearchAndFilter = styled.div`
  display: flex;
  margin-right: 3rem;
`

export const ContainerFilter = styled.div`
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  >svg{
    cursor: pointer;
    height: 30px;
    width: 30px;

    &:focus, &:hover {
      path{
        stroke: ${style.colors.primaryColor};
      }
    }
  }
`


export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 200px;

  > svg {
    position: absolute;
  }

  .magnifier {
    right: 10px;
    bottom: 10px;
    opacity: 0.8;
    path{
      fill: #808080;
    }
  }
`;

export const InputSearch = styled.input`
  /* font-family: Rubik; */
  height: 40px;
  width: 100%;

  /* border: 1px solid #E4E4E5; */
  border: 2px solid #e4e4e5;
  border-radius: 8px;
  background-color: #F4F6FB;
  padding: 10px 40px 10px 10px;
  font-size: 16px;

  color: #808080;

  &::placeholder {
    color: #808080;
  }

  &:focus, &:hover {
    border: none;
    outline: 2px solid ${style.colors.primaryColor} !important;
  }
`;

export const ContainerResults = styled.div`
  min-height: 70px;
  max-height: 70px;
  width: 300px;
  padding-top: 1.3rem;

  display: flex;
  flex-direction: column;

  margin: 0 1rem 0 auto;

  span {
    font-weight: 400;
    font-size: 14px;
    color: #808080;
  }
`

export const ContainerRight = styled.div`
  display: flex;
`
