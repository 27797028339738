import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";
import Flex from "components/core/Grid/Flex";
import { Link } from "react-router-dom";

export const BodyFiles = styled(Flex)(
  css({
    height: "100%",
    width: "100%",
    marginBottom: "120px",
    alignItems: "center",
    justifyItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    a: {
      ":hover": {
        textDecoration: "none",
      },
    },
    "@media (max-width: 342px)": {
      paddingLeft: "80px",
    },

    "@media (min-width: 366px) and (max-width: 386px)": {
      paddingLeft: "40px",
    },
    "@media (min-width: 386px) and (max-width: 490px)": {
      paddingLeft: "60px",
    },
  }),
);

export const ContainerNotFilesFound = styled(Flex)(
  css({
    marginTop: "100px",
    alignItems: "center",
    justifyItems: "center",
    svg: {
      g: {
        g: {
          rect: {
            fill: theme.colors.primaryColor,
          },
        },
      },
      defs: {
        linearGradient: {
          stop: {
            ":first-of-type": {
              stopColor: theme.colors.primaryColor,
            },
            ":last-of-type": {
              stopColor: theme.colors.secondaryColor,
            },
          },
        },
      },
    },
  }),
);

export const Folder = styled("div")(
  css({
    width: "56px",
    display: "flex",
    height: "56px",
    alignItems: "center",
    // marginBottom: "15px",
    borderRadius: "9.6px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.colors.paleGrey,
    ":hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    "@media (max-width: 320px)": {
      width: "120px",
      height: "120px",
    },
    svg: {
      "#primary_stop_color": {
        stopColor: theme.colors.primaryColor,
      },
      "#secondary_stop_color": {
        stopColor: theme.colors.secondaryColor,
      },
    },
  }),
);

export const FolderName = styled("h3")(
  css({
    margin: "0 0 0 0",
    fontSize: 2,
    fontWeight: "400",
    // height: "70px",
    // width: "120px",
    fontFamily: "text",
    lineHeight: "24px",
    overflow: "hidden",
    textAlign: "left",
    color: "charcoalGrey",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  }),
);

export const FolderPath = styled("div")(
  css({
    height: "32px",
    display: "flex",
    padding: "15px",
    alignItems: "center",
    justifyItems: "center",
    backgroundColor: theme.colors.paleGrey,
  }),
);

export const SubFolder = styled("div")(
  css({
    width: 56,
    display: "flex",
    height: 56,
    alignItems: "center",
    borderRadius: "9.6px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.colors.paleGrey,
    cursor: "pointer",
    svg: {
      width: 29,
      "#primary_stop_color": {
        stopColor: theme.colors.primaryColor,
      },
      "#secondary_stop_color": {
        stopColor: theme.colors.secondaryColor,
      },
    },
  }),
);

export const ItemFolder = styled(Flex)(
  css({
    justifyItems: "left",
    borderBottom: `1px solid ${theme.colors.paleGrey}`,
    ":first-of-type": {
      borderTop: `1px solid ${theme.colors.paleGrey}`,
    },
  }),
);

export const LinkFolder = styled(Link)(
  css({
    textDecoration: "none !important",
  }),
);

export const LinkFile = styled("a")(
  css({
    textDecoration: "none !important",
    "#Rectangle_Copy_5": {
      fill: theme.colors.primaryColor,
    },
  }),
);

export const WordSearched = styled("h4")(
  css({
    mb: 2,
    fontSize: 2,
    color: "gray",
    display: "block",
    fontFamily: `${theme.fonts.textItalic}`,
  }),
);

export const CircleSearched = styled("div")(
  css({
    mr: 2,
    width: 8,
    height: 8,
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: `${theme.colors.secondaryColor}`,
  }),
);

export const SubTitle = styled("h2")(
  css({
    fontSize: 18,
    color: "backText",
    fontFamily: "textLight",
    wordBreak: "break-all",
  }),
);

export const NameDetails = styled(SubTitle)(
  css({
    m: 0,
    fontSize: 1,
    color: "gray",
  }),
);

export const BoxButton = styled("div")(
  css({
    height: "70px",
    display: "flex",
    alignItems: "center",

    button: {
      margin: "0 10px",
    },
  }),
);

export const Breadcrumb = styled("div")(
  css({
    padding: "15px 20px",
    backgroundColor: "#F4F6FB",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  }),
);

export const BreadcrumbItem = styled(Link)(
  css({
    fontSize: "18px",
    lineHeight: 1.15,
    display: "flex",
    color: "#808080",
    alignItems: "center",
    textDecoration: "none !important",

    "&:after": {
      content: "'›'",
      display: "inline-block",
      fontSize: 24,
      lineHeight: 1.15,
      margin: "0 10px",
    },

    "&:last-child": {
      color: "#808080 !important",
      pointerEvents: "none",

      "&:after": {
        display: "none",
      },
    },
  }),
);
export const LineContainer = styled(Link)`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e4e4e5;
  padding-bottom: 1rem;

  & + & {
    margin-top: 1rem;
  }

  &:last-child {
    margin-right: 1.5rem;
  }

  &:first-child {
    border-top: 1px solid #e4e4e5;
    padding-top: 1rem;
  }

  > a > svg {
    height: 100%;
    margin: auto 0;
  }

  > div > svg{
    width: 30px;
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  padding-top: 5px;
`;

export const UserName = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18.66px;
  margin-top: 8px;
  color: #808080;
`;

export const ContainerNotFoundGeneric = styled.div`
  margin-top: 2rem;

  > div {
    svg {
      height: 140px;
    }

    > div {
      font-size: 20px;
    }
  }
`;
